import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const FooterFive = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="social-icons">
          <a
            target="_blank"
            href="https://twitter.com/Fuzionestpvtltd"
            rel="noreferrer"
          >
            <FaTwitter />
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/fuzionest/mycompany/?viewAsMember=true"
            rel="noreferrer"
          >
            <FaLinkedinIn />
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/profile.php?id=100088143221117&mibextid=LQQJ4d"
            rel="noreferrer"
          >
            <FaFacebookF />
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/fuzionest_limited/"
            rel="noreferrer"
          >
            <FaInstagram />
          </a>
        </div>
        <div className="copyright-text">
          <p>
            &copy; 2024 &nbsp;
            <Link to="/">Fuzionest, Private Limited</Link>&nbsp;|&nbsp;&nbsp;
            <Link to="/privacy-policy">Privacy Policy</Link>&nbsp;|&nbsp;&nbsp;
            <Link to="/website-terms">Website Terms</Link>&nbsp;|&nbsp;&nbsp;
            <Link to="/website-Accessibility">Accessibility</Link>&nbsp;|&nbsp;&nbsp;
            <Link to="/cookies-policy">Cookies Policy</Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterFive;

import React from "react";

const Technologypagethree = () => {
  return (
    <div className="technologypagethree-main">
      <div className="technopage3-main" style={{ padding: "0 10%" }}>
        <div className="row" style={{ borderBottom: "1px dashed black" }}>
          <div className="col-12 col-sm-12 col-md-4">
            <p className="column10 column-corp" id="back-end">
              Back-End
            </p>
          </div>
          <div
            className="col-12 col-sm-12 col-md-4"
            style={{ marginBottom: "5%", marginTop: "-3%" }}
          >
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              LAMP (Linux, Apache,MySQL, PHP)
            </p>
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              MERN (MongoDB, Express.js, React, Node.js)
            </p>
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              Java Spring
            </p>
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              Laravel (PHP)
            </p>
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              NestJS (Node.js)
            </p>
            {/* <p className='column12' style={{color: 'black'}}>WPF</p> */}
          </div>
          <div
            className="col-12 col-sm-12 col-md-4"
            style={{ marginBottom: "5%", marginTop: "-3%" }}
          >
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              MEAN ( MongoDB, EXPRESS.JS, Angular, Node.js )
            </p>
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              Django (Python)
            </p>
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              Flask (Python)
            </p>
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              {" "}
              Express.js (Node.js)
            </p>
            {/* <p className='column12' style={{borderBottom: '1px solid black',color: 'black'}}>SignalR</p> */}
          </div>
        </div>

        <div className="row" style={{ borderBottom: "1px dashed black" }}>
          <div
            className="col-12 col-sm-12 col-md-4"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column10 column-corp">Front-End</p>
          </div>
          <div
            className="col-12 col-sm-12 col-md-4"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              Angular
            </p>
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              Vue.js
            </p>
            {/* <p className='column12'style={{borderBottom: '1px solid black',color: 'black'}}>Kotlin</p> */}
            {/* <p className='column12'style={{borderBottom: '1px solid black',color: 'black'}}>Groovy</p> */}
            {/* <p className='column12'style={{borderBottom: '1px solid black',color: 'black'}}>Gradle</p> */}
            {/* <p className='column10'>WPF</p> */}
          </div>
          <div
            className="col-12 col-sm-12 col-md-4"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              React
            </p>
            {/* <p className='column12' style={{borderBottom: '1px solid black',color: 'black'}}>Spring Boot</p> */}
            {/* <p className='column12' style={{borderBottom: '1px solid black',color: 'black'}}>Hibernate</p> */}
            {/* <p className='column12' style={{borderBottom: '1px solid black',color: 'black'}}>SOAP/ REST</p> */}
            {/* <p className='column9' style={{borderBottom: '1px solid black'}}>SignalR</p> */}
          </div>
        </div>

        <div className="row" style={{ borderBottom: "1px dashed black" }}>
          <div
            className="col-12 col-sm-12 col-md-4"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column10 column-corp">Mobile</p>
          </div>
          <div
            className="col-12 col-sm-12 col-md-4"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              Typescript
            </p>
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              TypeORM
            </p>
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              Sequelize
            </p>
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              Express
            </p>
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              Gradle
            </p>
            {/* <p className='column10'>WPF</p> */}
          </div>
          <div
            className="col-12 col-sm-12 col-md-4"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              Nest.js
            </p>
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              LoopBack
            </p>
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              Socket.IO
            </p>
            <p className="column12" style={{ borderBottom: "1px solid black" }}>
              SOAP/ REST
            </p>
            {/* <p className='column9' style={{borderBottom: '1px solid black'}}>SignalR</p> */}
          </div>

          {/* <h5 className='footer-heading' style={{ paddingLeft: '10%', paddingRight: '2%' }}>Fuzionest Designers</h5>
                    <p style={{ paddingLeft: '13%', paddingRight: '2%',marginBottom: '5%', color: 'black' }}>UI/UX Designers</p>  */}
        </div>

        <div className="row" style={{ borderBottom: "1px dashed black" }}>
          <div
            className="col-12 col-sm-12 col-md-4"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column10 column-corp">IoT</p>
          </div>
          <div
            className="col-12 col-sm-12 col-md-4"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column12" style={{}}>
              PHP (Zend, Symfony, WordPress, Magento)
            </p>
            {/* <p className='column10'style={{borderBottom: '1px solid black'}}>TypeORM</p>
                    <p className='column10'style={{borderBottom: '1px solid black'}}>Sequelize</p>
                    <p className='column10'style={{borderBottom: '1px solid black'}}>Express</p> */}
            {/* <p className='column10'style={{borderBottom: '1px solid black'}}>Gradle</p> */}
            {/* <p className='column10'>WPF</p> */}
          </div>
          <div
            className="col-12 col-sm-12 col-md-4"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column12" style={{}}>
              Python (Tornado, Django, Flask)
            </p>
            {/* <p className='column9' style={{borderBottom: '1px solid black'}}>Spring Boot</p>
                    <p className='column9' style={{borderBottom: '1px solid black'}}>Hibernate</p> */}
            {/* <p className='column9' style={{borderBottom: '1px solid black'}}>SOAP/ REST</p> */}
            {/* <p className='column9' style={{borderBottom: '1px solid black'}}>SignalR</p> */}
          </div>

          {/* <h5 className='footer-heading' style={{ paddingLeft: '10%', paddingRight: '2%' }}>Fuzionest Designers</h5>
                    <p style={{ paddingLeft: '13%', paddingRight: '2%',marginBottom: '5%', color: 'black' }}>UI/UX Designers</p>  */}
        </div>

        <div className="row" style={{ borderBottom: "1px dashed black" }}>
          <div
            className="col-12 col-sm-12 col-md-4"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column10  column-corp">DevOps/Cloud</p>
          </div>
          <div
            className="col-12 col-sm-12 col-md-4"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column12" style={{}}>
              PHP (Zend, Symfony, WordPress, Magento)
            </p>
            {/* <p className='column10'style={{borderBottom: '1px solid black'}}>TypeORM</p>
                    <p className='column10'style={{borderBottom: '1px solid black'}}>Sequelize</p>
                    <p className='column10'style={{borderBottom: '1px solid black'}}>Express</p> */}
            {/* <p className='column10'style={{borderBottom: '1px solid black'}}>Gradle</p> */}
            {/* <p className='column10'>WPF</p> */}
          </div>
          <div
            className="col-12 col-sm-12 col-md-4"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column12" style={{}}>
              Python (Tornado, Django, Flask)
            </p>
            {/* <p className='column9' style={{borderBottom: '1px solid black'}}>Spring Boot</p>
                    <p className='column9' style={{borderBottom: '1px solid black'}}>Hibernate</p> */}
            {/* <p className='column9' style={{borderBottom: '1px solid black'}}>SOAP/ REST</p> */}
            {/* <p className='column9' style={{borderBottom: '1px solid black'}}>SignalR</p> */}
          </div>

          {/* <h5 className='footer-heading' style={{ paddingLeft: '10%', paddingRight: '2%' }}>Fuzionest Designers</h5>
                    <p style={{ paddingLeft: '13%', paddingRight: '2%',marginBottom: '5%', color: 'black' }}>UI/UX Designers</p>  */}
        </div>

        <div className="row">
          <div
            className="col-12 col-sm-12 col-md-4"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column10  column-corp">AI&ML/Data science</p>
          </div>
          <div
            className="col-12 col-sm-12 col-md-4"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column12" style={{}}>
              PHP (Zend, Symfony, WordPress, Magento)
            </p>
            {/* <p className='column10'style={{borderBottom: '1px solid black'}}>TypeORM</p>
                    <p className='column10'style={{borderBottom: '1px solid black'}}>Sequelize</p>
                    <p className='column10'style={{borderBottom: '1px solid black'}}>Express</p> */}
            {/* <p className='column10'style={{borderBottom: '1px solid black'}}>Gradle</p> */}
            {/* <p className='column10'>WPF</p> */}
          </div>
          <div
            className="col-12 col-sm-12 col-md-4"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column12" style={{}}>
              Python (Tornado, Django, Flask)
            </p>
            {/* <p className='column9' style={{borderBottom: '1px solid black'}}>Spring Boot</p>
                    <p className='column9' style={{borderBottom: '1px solid black'}}>Hibernate</p> */}
            {/* <p className='column9' style={{borderBottom: '1px solid black'}}>SOAP/ REST</p> */}
            {/* <p className='column9' style={{borderBottom: '1px solid black'}}>SignalR</p> */}
          </div>

          {/* <h5 className='footer-heading' style={{ paddingLeft: '10%', paddingRight: '2%' }}>Fuzionest Designers</h5>
                    <p style={{ paddingLeft: '13%', paddingRight: '2%',marginBottom: '5%', color: 'black' }}>UI/UX Designers</p>  */}
        </div>
      </div>
    </div>
  );
};

export default Technologypagethree;

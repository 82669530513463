import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import Technologypageone from "../components/Technologypageone";
import Technologypagetwo from "../components/Technologypagetwo";
import Technologypagethree from "../components/Technologypagethree";
import { Helmet } from "react-helmet";

const Team = () => {
  const browserTabTitle =
    "Innovative Technology Solutions for Modern Businesses | Fuzionest";
  const seoTitle =
    "Innovative Technology Solutions for Modern Businesses | Fuzionest";
  const seoDescription =
    "Discover cutting-edge technology solutions at Fuzionest. We specialize in advanced tech consulting, AI-driven automation, and tailored project development to empower businesses with innovation and efficiency. Explore our services and transform your operations with the latest technological advancements.";
  const seoKeywords =
    "Technology solutions, tech consulting, ai automation, project development, business automation, business innovation, tech advancements, tech services, IT solutions, enterprise technology, digital transformation, wen development, ios development, android development.";
  return (
    <>
      <Helmet>
        <title>{browserTabTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={seoKeywords} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        {/* Schema Markup */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "${browserTabTitle}",
              "url": "https://www.fuzionest.com/technology",
              "description": "${seoDescription}",
              "mainEntity": {
                "@type": "Organization",
                "name": "Fuzionest",
                "url": "https://www.fuzionest.com",
                "logo": "https://www.fuzionest.com/assets/img/landing/FuzionestLogo.svg",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "contactType": "Customer Service",
                  "telephone": "+91 84387 62921",
                  "email": "contact@fuzionest.com",
                  "address": {
                    "@type": "PostalAddress",
                    "addressLocality": "Coimbatore",
                    "addressRegion": "Tamil Nadu",
                    "postalCode": "641014",
                    "addressCountry": "India",
                    "streetAddress": "Tidel Park, Module No.12 Ground floor Elcot SEZ Coimbatore IT Park Road"
                  }
                }
              }
            }
          `}
        </script>
      </Helmet>

      {/* Navigation Bar */}
      <NavBar />
      <Technologypageone />
      <Technologypagetwo />
      <Technologypagethree />

      {/* Breadcrumb */}
      {/* <Breadcrumb title={"Team"} /> */}

      {/* Team Area Group */}
      {/* <TeamAreaGroup /> */}

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default Team;

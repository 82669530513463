import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaMinus } from "react-icons/fa";

const Servicehomepage4 = () => {
  return (
    <footer className="bg-white text-black py-4">
      <div className="container">
        <div className="row" style={{ borderBottom: "1px dashed black" }}>
          <div className="col-12 col-md-4">
            <h2
              className="column-corp"
              id="tech-2"
              style={{ color: "#202021 " }}
            >
              Cooperation models
            </h2>
          </div>
          <div className="col-12 col-md-4">
            <p className="column-corp"></p>
          </div>
          <div className="col-12 col-md-4" style={{ fontSize: "16px" }}>
            <p className="column-corp" id="tech-2">
              We offer the following partnership models depending on your
              specifications and business goals.
            </p>
          </div>
        </div>

        <div className="row row-01" style={{ borderBottom: "1px dashed black" }}>
          <div
            className="col-12 col-sm-6 col-md-4"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column-corp" style={{ fontSize: "140%" }}>
              01
            </p>
          </div>
          <div
            className="footer-column"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column10">Team augmentation</p>
          </div>
          <div
            className="footer-column"
            style={{ marginBottom: "5%", marginTop: "7%" }}
          >
            <p className="column19">
              Our software engineers Join your tech team to increage quality and
              development speed.
            </p>
          </div>
        </div>

        <div className="row row-01" style={{ borderBottom: "1px dashed black" }}>
          <div
            className="col-12 col-sm-6 col-md-4"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column-corp" style={{ fontSize: "140%" }}>
              02
            </p>
          </div>
          <div
            className="footer-column"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column10">Dedicated team</p>
          </div>
          <div
            className="footer-column"
            style={{ marginBottom: "5%", marginTop: "7%" }}
          >
            <p className="column19">
              We gather a team of highly skilled developers, testers, and Scrum
              Masters based on project requirements.
            </p>{" "}
          </div>
        </div>

        <div className="row row-01" style={{ borderBottom: "1px dashed black" }}>
          <div
            className="col-12 col-sm-6 col-md-4"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column-corp" style={{ fontSize: "140%" }}>
              03
            </p>
          </div>
          <div
            className="footer-column"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column10">Product development</p>
          </div>
          <div className="footer-column" style={{ marginTop: "7%" }}>
            <p className="column19">
              Whether you need to build a product from scratch or improve it, we
              assemble the best tech team to implement your ideas and achieve
              your goals.
            </p>
          </div>
        </div>

        <div className="row row-01" style={{ borderBottom: "1px dashed black" }}>
          <div
            className="col-12 col-sm-6 col-md-4"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column-corp" style={{ fontSize: "140%" }}>
              04
            </p>
          </div>
          <div
            className="footer-column"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p className="column10">White label</p>
          </div>
          <div className="footer-column" style={{ marginTop: "7%" }}>
            <p className="column19">
              Get an expert white-labeled product development service to fill
              the gaps in the expertise of your product team.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-4">
            <h3
              className="column-corp"
              style={{ color: "#202021 ", fontWeight: "700" }}
            >
              Work process
            </h3>
          </div>
          <div className="col-12 col-md-4">
            <p className="column4"></p>
          </div>
          <div className="col-12 col-md-4">
            <p
              className="column-corp"
              style={{ textAlign: "justify", textJustify: "inter-word" }}
            >
              We have a well-established Agile process for full-cycle software
              development. As an Agile company, we welcome changes. If you wish
              to bring your process, we'll adjust to them quickly.
            </p>
          </div>
        </div>

        <div
          className="row"
          style={{ borderTop: "1px dashed black", width: "100%" }}
        >
          <div
            className="footer-column discover-box"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p style={{ color: "black" }}>01</p>
            <h3
              className="heading-discover"
              style={{ color: "#202021 ", marginLeft: "5%" }}
            >
              Discovery
            </h3>
            <p className="column4" style={{ marginLeft: "5%" }}>
              <FaMinus style={{ marginRight: "2%" }} />
              Initial request.
            </p>
            <p className="column4" style={{ marginLeft: "5%" }}>
              <FaMinus style={{ marginRight: "2%" }} />
              High-level requirements document, sample links, etc.
            </p>
            <p className="column4" style={{ marginLeft: "5%" }}>
              <FaMinus style={{ marginRight: "2%" }} />
              Mutual NDA.
            </p>
            <p className="column4" style={{ marginLeft: "5%" }}>
              <FaMinus style={{ marginRight: "2%" }} />
              Discovery call.
            </p>
          </div>
          <div
            className="footer-column discover-box column11"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p style={{ color: "black" }}>02</p>
            <h3
              className="heading-discover"
              style={{ color: "#202021 ", marginLeft: "5%" }}
            >
              Pre-Engagement
            </h3>
            <p className="column4" style={{ marginLeft: "5%" }}>
              <FaMinus style={{ marginRight: "2%" }} />
              Estimation / Proposal.
            </p>
            <p className="column4" style={{ marginLeft: "5%" }}>
              <FaMinus style={{ marginRight: "2%" }} />
              Negotiation.
            </p>
            <p className="column4" style={{ marginLeft: "5%" }}>
              <FaMinus style={{ marginRight: "2%" }} />
              Scope definition and approval.
            </p>
            <p className="column4" style={{ marginLeft: "5%" }}>
              <FaMinus style={{ marginRight: "2%" }} />
              Account Manager assignment.
            </p>
            <p className="column4" style={{ marginLeft: "5%" }}>
              <FaMinus style={{ marginRight: "2%" }} />
              Planing.
            </p>
            <p className="column4" style={{ marginLeft: "5%" }}>
              <FaMinus style={{ marginRight: "2%" }} />
              Requirements and Feasibility analysis.
            </p>
          </div>
          <div
            className="footer-column discover-box column11"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <p style={{ color: "black" }}>03</p>
            <h3
              className="heading-discover"
              style={{ color: "#202021 ", marginLeft: "5%" }}
            >
              Engagement
            </h3>
            <p className="column4" style={{ marginLeft: "5%" }}>
              <FaMinus style={{ marginRight: "2%" }} />
              MSA AND SOW
            </p>
            <p className="column4" style={{ marginLeft: "5%" }}>
              <FaMinus style={{ marginRight: "2%" }} />
              Interview.
            </p>
            <p className="column4" style={{ marginLeft: "5%" }}>
              <FaMinus style={{ marginRight: "2%" }} />
              Evalution and hiring negotiation.
            </p>
          </div>
        </div>
        <div
          className="row"
          style={{
            borderTop: "1px dashed black",
            borderBottom: "1px dashed black",
          }}
        >
          <div className="col-12 col-md-4" style={{ marginTop: "2%" }}>
            <h3
              className="column-corp"
              style={{ color: "#202021 ", fontWeight: "700" }}
            >
              Technology stack
            </h3>
          </div>
          <div className="col-12 col-md-4">
            <p className="column4"></p>
          </div>
          <div className="col-12 col-md-4">
            <p
              className="column-corp"
              style={{
                textAlign: "justify",
                textJustify: "inter-word",
                marginBottom: "10%",
                marginTop: "10%",
              }}
            >
              We seamlessly improve our technical expertise and introduce the
              newest technology solutions. This is our approach — we exceed
              expectations to ensure the unparalleled quality of service{" "}
            </p>
          </div>
        </div>

        <div
          className="row"
          style={{ paddingLeft: "10%", paddingRight: "2%" }}
        ></div>
      </div>
    </footer>
  );
};

export default Servicehomepage4;

import React from "react";
import "./Stopthink.css";
import config from "../utils/config";
// import Image from '../../public/assets/img/5.png'

const Servicehomepage3 = () => {
  return (
    <div className="stopthink stopthink-service">
      <img
        // src="assets/img/5.png"
        src={`${config.baseUrl}/assets/img/5.png`}
        alt="The Globe"
        className="left-image"
        id="stopthinkimage"
        style={{ width: "20%" }}
      />
      <div className="content">
        <div className="text-content">
          <h2 className="Servicehomepage3-heading">
            Fuzionest Brings Together The Highest Echelon Of Talent From Across
            The Globe, Comprising The Top 7%.
          </h2>
        </div>
      </div>
      <img
        src="assets/img/5.png"
        alt="Talent"
        className="right-image"
        style={{ width: "20%" }}
      />
    </div>
  );
};

export default Servicehomepage3;

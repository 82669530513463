import React, { useRef, useState } from "react";
import Slider from "react-slick";
import { FaPlay, FaPause, FaArrowLeft, FaArrowRight } from "react-icons/fa";

function News() {
  const sliderRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const togglePlayPause = () => {
    if (isPlaying) {
      sliderRef.current.slickPause();
    } else {
      sliderRef.current.slickPlay();
    }
    setIsPlaying(!isPlaying);
  };

  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          autoplay: true,
          speed: 5000,
          autoplaySpeed: 1000,
          cssEase: "linear",
          pauseOnHover: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          autoplay: true,
          speed: 5000,
          autoplaySpeed: 1000,
          cssEase: "linear",
          pauseOnHover: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          autoplay: true,
          speed: 5000,
          autoplaySpeed: 1000,
          cssEase: "linear",
          pauseOnHover: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          speed: 5000,
          autoplaySpeed: 1000,
          cssEase: "linear",
          pauseOnHover: true,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          speed: 5000,
          autoplaySpeed: 1000,
          cssEase: "linear",
          pauseOnHover: true,
        },
      },
    ],
  };

  return (
    <div className="slider-container p-2">
      <h2 className="p-2">Why Fuzionest?</h2>
      <Slider ref={sliderRef} {...settings}>
        <div className="p-2">
          <h3>Innovation at the Core</h3>
          <p>
            “Fuzionest thrives on innovation. Our agency continually seeks out
            new technologies, frameworks, and methodologies to stay ahead in the
            rapidly evolving world of software development. We embrace change
            and use it to create cutting-edge solutions that give our clients a
            competitive advantage.”
          </p>
        </div>
        <div className="p-2">
          <h3>Tailored Solutions</h3>
          <p>
            “We understand that every client is unique, with distinct goals and
            requirements. Our approach involves closely collaborating with
            clients to understand their needs, objectives, and challenges. This
            enables us to craft bespoke solutions that precisely address their
            business needs and lead to tangible results.”
          </p>
        </div>
        <div className="p-2">
          <h3>Timely Delivery</h3>
          <p>
            “We understand that time is of the essence in today's fast-paced
            business environment. Our proven project management methodologies
            and efficient processes allow us to deliver projects on time,
            without compromising on quality. We believe in setting realistic
            timelines and sticking to them.”
          </p>
        </div>
      </Slider>
      <div className="d-flex justify-content-between align-items-center gap-2 mt-2">
        <button
          className="btn btn-black border-radius-0"
          onClick={togglePlayPause}
          style={{ background: "#2D2D2D" }}
        >
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>
        <div className="d-flex gap-2">
          <button
            className="btn btn-black border-radius-0"
            onClick={() => {
              sliderRef.current.slickPause();
              sliderRef.current.slickNext();
            }}
            style={{ background: "#2D2D2D" }}
          >
            <FaArrowLeft />
          </button>
          <button
            className="btn btn-black border-radius-0"
            onClick={() => {
              sliderRef.current.slickPause();
              sliderRef.current.slickPrev();
            }}
            style={{ background: "#2D2D2D" }}
          >
            <FaArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
}

export default News;

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import config from "../utils/config";

const GridExample = () => {
  return (
    <div className="container">
      <div className="servicepageone-heading">
        <h2 className="content2" style={{ fontWeight: "700" }}>
          The Fuzionest Screening Process
        </h2>
        <p className="contentpara5" style={{ paddingTop: "30px" }}>
          Every candidate aspiring to join the Fuzionest network undergoes a
          screening procedure meticulously crafted to assess their proficiency
          in the respective subject, level of professionalism, and adeptness in
          communication. The comprehensive screening process typically spans
          several weeks to finalize.
        </p>
      </div>
      <div
        className="row justify-content-center gx-4"
        style={{ marginTop: "60px" }}
      >
        <div className="col-md-3 mb-4" style={{ margin: "0% 1%" }}>
          <p className="service-steps">Step 1</p>

          <div className="row">
            <div className="column">
              <img
                className="animate-img-servicehome"
                // src="assets/img/Servicehomepage1/1.png"
                src={`${config.baseUrl}/assets/img/Servicehomepage1/1.png`}
                alt="application"
              />
            </div>
            <div className="column">
              <p
                className="service-percentage"
                style={{ width: "100%", marginTop: "30px" }}
              >
                <span style={{ fontWeight: "800" }}> 41 %</span>
                <br />
                of application pass
              </p>
            </div>
          </div>

          <h6
            className="servicehomepage-head6"
            style={{
              fontWeight: "bolder",
              fontSize: "20px",
              marginBottom: "20px",
              marginTop: "15%",
            }}
          >
            Language and Personality
          </h6>
          <p className="servicehomepage-p">
            The first step of the screening process is a comprehensive English
            language and communication interview evaluation. We also assess
            personality traits, looking for candidates who are passionate and
            fully engaged in their work.
          </p>
        </div>
        <div className="col-md-3 mb-4" style={{ margin: "0% 1%" }}>
          <p className="service-steps">Step 2</p>

          <div className="row">
            <div className="column">
              <img
                className="animate-img-servicehome"
                // src="assets/img/Servicehomepage1/2.png"
                src={`${config.baseUrl}/assets/img/Servicehomepage1/2.png`}
                alt="technical knowledge"
              />
            </div>
            <div className="column">
              <p
                className="service-percentage"
                style={{ width: "100%", marginTop: "30px" }}
              >
                <span
                  style={{
                    fontWeight: "800",
                    fontFamily: "Roboto, sans-serif",
                  }}
                >
                  16.7 %
                </span>
                <br />
                of application pass
              </p>
            </div>
          </div>

          <h6
            className="servicehomepage-head6"
            style={{
              fontWeight: "bolder",
              fontSize: "20px",
              marginBottom: "20px",
              marginTop: "15%",
            }}
          >
            In Depth Skill Review
          </h6>
          <p className="servicehomepage-p">
            We also test each applicant's technical knowledge and problem
            solving ability through various assessments. Every member of the
            Fuzionest network is an expert in their domain, and we typically
            only advance candidates with exceptional results in this phase.
          </p>
        </div>
        <div className="col-md-3 mb-4" style={{ margin: "0% 1%" }}>
          <p className="service-steps">Step 3</p>
          <div className="row">
            <div className="column">
              <img
                className="animate-img-servicehome"
                // src="assets/img/Servicehomepage1/3.png"
                src={`${config.baseUrl}/assets/img/Servicehomepage1/3.png`}
                alt="Live Screening"
              />
            </div>
            <div className="column">
              <p
                className="service-percentage"
                style={{ width: "100%", marginTop: "30px" }}
              >
                {" "}
                <span
                  style={{
                    fontWeight: "800",
                    fontFamily: "Roboto, sans-serif",
                  }}
                >
                  {" "}
                  7.8 %
                </span>
                <br />
                of application pass
              </p>
            </div>
          </div>

          <h6
            className="servicehomepage-head6"
            style={{
              fontWeight: "bolder",
              fontSize: "20px",
              marginBottom: "20px",
              marginTop: "15%",
            }}
          >
            Live Screening
          </h6>
          <p className="servicehomepage-p">
            Each candidate is interviewed by Fuzionest screeners who are experts
            in their functional domain. Our screeners provide specific live
            exercises, looking for problem solving ability, depth of experience,
            communication ability, and creativity.
          </p>
        </div>
      </div>
      <div className="row justify-content-center gx-4">
        <div className="col-md-3 mb-4" style={{ margin: "0% 1%" }}>
          <p className="service-steps">Step 4</p>
          <div className="row">
            <div className="column">
              <img
                className="animate-img-servicehome"
                // src="assets/img/Servicehomepage1/4.png"
                src={`${config.baseUrl}/assets/img/Servicehomepage1/4.png`}
                alt="Test Projects"
              />
            </div>
            <div className="column">
              <p
                className="service-percentage"
                style={{ width: "100%", marginTop: "30px" }}
              >
                <span
                  style={{
                    fontWeight: "800",
                    fontFamily: "Roboto, sans-serif",
                  }}
                >
                  7.3 %
                </span>
                <br />
                of application pass
              </p>
            </div>
          </div>

          <h6
            className="servicehomepage-head6"
            style={{
              fontWeight: "bolder",
              fontSize: "20px",
              marginBottom: "20px",
              marginTop: "15%",
            }}
          >
            Test Projects
          </h6>
          <p className="servicehomepage-p">
            Each candidate is assigned a test project to evaluate whether they
            can walk the walk." Test projects take 1-3 weeks are comprehensive
            and provide real world scenarios for candidates to demonstrate their
            competence, thoroughness, professionalism, and integrity.
          </p>
        </div>
        <div className="col-md-3 mb-4" style={{ margin: "0% 1%" }}>
          <p className="service-steps">Step 5</p>
          <div className="row">
            <div className="column">
              <img
                className="animate-img-servicehome"
                // src="assets/img/Servicehomepage1/5.png"
                src={`${config.baseUrl}/assets/img/Servicehomepage1/5.png`}
                alt="Continued Excellence"
              />
            </div>
            <div className="column">
              <p
                className="service-percentage"
                style={{ width: "100%", marginTop: "30px" }}
              >
                <span
                  style={{
                    fontWeight: "800",
                    fontFamily: "Roboto, sans-serif",
                  }}
                >
                  7 %{" "}
                </span>
                <br />
                of application pass
              </p>
            </div>
          </div>

          <h6
            className="servicehomepage-head6"
            style={{
              fontWeight: "bolder",
              fontSize: "20px",
              marginBottom: "20px",
              marginTop: "15%",
            }}
          >
            Continued Excellence
          </h6>
          <p className="servicehomepage-p">
            Members of the Fuzionest network maintain a track record of
            excellence while working with clients. As a quality first company's,
            our focus is on the top for talent and the top for clients, and this
            principle permeates through to every engagement and every delivered
            project.
          </p>
        </div>
      </div>
    </div>
  );
};

export default GridExample;

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/bootstrap.css";
import countryList from "../utils/country.json";

const Contactarea1 = () => {
  const initialFormData = {
    name: "",
    email: "",
    phone: "+91",
    company: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const inputRef = useRef(null);

  useEffect(() => {
    // Check if the URL contains #ContactDetails
    if (window.location.href.includes("#ContactDetails")) {
      // Set focus on the input field using the ref
      if (inputRef.current) {
        inputRef.current.focus();
        window.scrollBy(0, 750);
      }
    }
  }, []);

  // const [isButtonLoading, setIsButtonLoading] = useState(false);

  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    country: Yup.string().required("Country is required"),
    phone: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number is not valid")
      .required("Phone number is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    message: Yup.string().required("Message is required"),
    company: Yup.string().required("Company is required"),
  });

  // Initialize React Hook Form
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setIsButtonLoading(true);
    try {
      const response = await fetch("https://api.fuzionest.com/contactus", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // Request was successful, display a success toast
        toast.success("Form submitted successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        reset();
      } else {
        // Handle errors here.
        toast.error("Form submission failed");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message ? error.message : "Server Error!");
    } finally {
      setIsButtonLoading(false);
      reset();
    }
  };

  return (
    <div style={{ background: "#202020" }}>
      <div className="container">
        <div className="row m-0">
          <div className="col-lg-12 form-container">
            <form
              className="contact-form contact-style"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <h2
                  style={{
                    marginTop: "20px",
                    fontWeight: "bold",
                    marginBottom: "5%",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  Tell us about your project
                </h2>
              </div>
              <div className="mb-4 row align-items-center">
                <label
                  className="form-label col-md-4 text-md-right text-white"
                  htmlFor="name"
                >
                  Full Name <span className="text-danger">*</span>
                </label>
                <div className="col-md-8">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        id="name"
                        className="form-control input-style"
                        placeholder="Enter your name"
                      />
                    )}
                  />
                  <p className="text-danger">{errors.name?.message}</p>
                </div>
              </div>
              <div className="mb-4 row align-items-center text-white">
                <label
                  className="form-label col-md-4 text-md-right"
                  htmlFor="country"
                >
                  Country <span className="text-danger">*</span>
                </label>
                <div className="col-md-8">
                  <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (
                      <select
                        {...field}
                        id="country"
                        className="form-control input-style"
                      >
                        <option value="">Select your country</option>
                        {countryList.map((country) => (
                          <option key={country.name} value={country.value}>
                            {country.name.toUpperCase()} ({country.value})
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  <p className="text-danger">{errors.country?.message}</p>
                </div>
              </div>
              <div className="mb-4 row align-items-center text-white">
                <label
                  className="form-label col-md-4 text-md-right"
                  htmlFor="phone"
                >
                  Phone <span className="text-danger">*</span>
                </label>
                <div className="col-md-8">
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        id="phone"
                        className="form-control input-style"
                        placeholder="Enter your phone number"
                      />
                    )}
                  />
                  <p className="text-danger">{errors.phone?.message}</p>
                </div>
              </div>
              <div className="mb-4 row align-items-center">
                <label
                  className="form-label col-md-4 text-md-right text-white"
                  htmlFor="email"
                >
                  Email <span className="text-danger">*</span>
                </label>
                <div className="col-md-8">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        id="email"
                        className="form-control input-style"
                        placeholder="Enter your email"
                      />
                    )}
                  />
                  <p className="text-danger">{errors?.email?.message}</p>
                </div>
              </div>

              <div className="mb-4 row align-items-center">
                <label
                  className="form-label col-md-4 text-md-right text-white"
                  htmlFor="company"
                >
                  Company <span className="text-danger">*</span>
                </label>
                <div className="col-md-8">
                  <Controller
                    name="company"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        id="company"
                        className="form-control input-style"
                        placeholder="Enter your company"
                      />
                    )}
                  />
                  <p className="text-danger">{errors?.company?.message}</p>
                </div>
              </div>
              <div className="mb-4 row align-items-center">
                <label
                  className="form-label col-md-4 text-md-right text-white"
                  htmlFor="message"
                >
                  Message <span className="text-danger">*</span>
                </label>
                <div className="col-md-8">
                  <Controller
                    name="message"
                    control={control}
                    render={({ field }) => (
                      <textarea
                        {...field}
                        id="message"
                        className="form-control input-style"
                        placeholder="Enter here"
                        rows="5"
                      />
                    )}
                  />
                  <p className="text-danger">{errors.message?.message}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <ToastContainer />
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex flex-column flex-md-row justify-content-center gap-4">
                  <button
                    type="submit"
                    className="btn btn-primary mb-4 border-radius-0"
                    disabled={isButtonLoading}
                  >
                    {isButtonLoading ? (
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Send Message"
                    )}
                  </button>
                  <Link
                    className="btn btn-primary mb-4 border-radius-0"
                    to="https://calendly.com/contact-mmm"
                    style={{ borderWidth: "2px" }}
                  >
                    Book a Meeting
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row m-0">
          <div className="col-lg-12">
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactarea1;

import { Link } from "react-router-dom";

const OurServicesSection = () => {
  return (
    <div style={{ background: "#202020" }}>
      <div className="container py-2">
        <div className="security-content">
          <h2
            className="m-0"
            style={{
              fontWeight: "bold",
              color: "#fff",
              textAlign: "center",
            }}
          >
            Looking for the smart automations?
          </h2>
          <p style={{ color: "#FFF" }}>Let’s get in touch</p>
          <Link to="/contact">
            <button
              className="btn btn-base border-radius-0"
              // data-aos="fade-up"
              // data-aos-delay="400"
            >
              Contact Us
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OurServicesSection;

import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const Technologypageone = () => {
  return (
    <>
      {/* ================== BannerOne Start ==================*/}
      <div className="banner-area4 banner-area-1 pd-bottom-100 bg-cover">
        <div className="container" style={{ marginTop: "3%" }}>
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="banner-inner pe-xl-5">
                <h6
                  className="subtitle-technology"
                  data-aos="fade-right"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  How we work
                </h6>
                <h2
                  className="titletechnology"
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-duration="1500"
                  style={{ color: "white", display: "flex" }}
                >
                  The Way We Work: Our Approach to Delivering Results
                </h2>

                <p
                  className="contents-technology pe-xl-5"
                  data-aos="fade-right"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                  style={{
                    color: "white",
                    fontweight: "lighter",
                    lineHeight: "25px",
                  }}
                >
                  At Fuzionest, We collaborate closely with clients to create
                  tailor-made solutions that drive business success. Discover
                  how we deliver software development results with our expert
                  approach tailored to your business needs.
                </p>

                <div className="button-service-tech">
                  <Link
                    className="btn btn-base"
                    data-aos-delay="500"
                    data-aos-duration="1500"
                    to="/contact"
                    style={{
                      color:'#FFF',
                      marginTop: "5%",
                      fontWeight: "500",
                    }}
                  >
                    Let’s make it together
                    <FaArrowRight style={{ marginRight: "-1px" }} size={20} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5 col-md-8 offset-xl-2 offset-lg-1">
              <div
                className="banner-thumb mt-5 mt-lg-0 ms-4 ms-lg-0"
                data-aos="fade-left"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== BannerOne End ==================*/}
    </>
  );
};

export default Technologypageone;
